import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ListLink from '../ListLink/ListLink';
import Logo from '@hiredigital/ui/Logo/RefLogo';
import FooterNav from './Nav';
import Facebook from '../Svg/Facebook';
import Linkedin from '../Svg/Linkedin';
import Twitter from '../Svg/Twitter';

import { locales } from '../../../data/contact';
import Styles from './Footer.module.scss';

const Footer = memo(({ darkFooter, data, locale, footer, className }) => {
  const selectedLocale = locales.find((l) => l.locale === locale) || data;
  // const copyright = `Copyright © 2021. Hire Digital`;
  const listLinkType = darkFooter ? ListLink.Type.WHITE : ListLink.Type.DARKGRAY;
  return (
    <footer
      className={classNames(
        Styles.wrapper,
        darkFooter ? Styles.darkblue : className ? className : Styles.defaultBackground
      )}
      data-test-id='footer'>
      <div className={Styles.sitemap}>
        <div className={classNames(Styles.container, Styles.containerTop)}>
          <div className={Styles.description}>
            <Logo
              className={Styles.logo}
              type={darkFooter ? Logo.Type.WHITE : Logo.Type.DARKGRAY}
              site={'3'}
            />
            <p className={Styles.descriptionText}>
              {`Hire Digital helps businesses build and enhance their digital capabilities with a world-class network of digital marketers, developers, and designers. Clients leverage on-demand teams to gain control of key priorities, plan their seasonal resources, and launch pilots to success.`}
            </p>
          </div>
          <div className={Styles.flexSpacer} />
          <FooterNav title='Solutions' className={Styles.footerNav}>
            <ListLink type={listLinkType} className={Styles.navLink} to='/'>
              {`Home`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/clients'>
              {`Clients`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/enterprise'>
              {`Enterprise`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/talent'>
              {`Become a Talent`}
            </ListLink>
          </FooterNav>

          <FooterNav title='Resources' className={Styles.footerNav}>
            <ListLink type={listLinkType} className={Styles.navLink} to='/blog'>
              {`Blog`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/resources'>
              {`Resources`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/faq'>
              {`FAQ`}
            </ListLink>

            <ListLink type={listLinkType} className={Styles.navLink} to='/terms'>
              {`Terms`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/privacy'>
              {`Privacy`}
            </ListLink>
          </FooterNav>

          <FooterNav title='Company' className={Styles.footerNav}>
            <ListLink type={listLinkType} className={Styles.navLink} to='/about' external>
              {`About`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/contact'>
              {`Contact`}
            </ListLink>
            <ListLink type={listLinkType} className={Styles.navLink} to='/careers'>
              {`Careers`}
            </ListLink>
          </FooterNav>
        </div>
      </div>

      <div className={classNames(Styles.contact, Styles.container)}>
        <div className={Styles.section}>
          <a
            data-test-id='footer-em'
            className={classNames(Styles.contactLink)}
            href={
              selectedLocale
                ? `mailto:${selectedLocale.email}`
                : `mailto:${process.env.NEXT_PUBLIC_CONTACT_EMAIL}`
            }
            title='Enquiry'>
            {selectedLocale ? selectedLocale.email : process.env.NEXT_PUBLIC_CONTACT_EMAIL}
          </a>
        </div>
        <div className={Styles.section}>
          <a
            data-test-id='footer-ph'
            className={classNames(Styles.contactLink)}
            href={`tel:${
              footer?.internationalNumber || selectedLocale?.phone || '+1 (617) 528-0943'
            }`}
            title='phone'>
            {footer?.internationalNumber || selectedLocale?.phone || '+1 (617) 528-0943'}
          </a>
        </div>
        <div className={classNames(Styles.section, Styles.rightSection)}>
          <a
            className={Styles.linkIcon}
            data-test-id='footer-fb'
            href={`${process.env.NEXT_PUBLIC_FACEBOOK_URL}`}
            rel='noopener noreferrer nofollow me'
            title={`Hire Digital Facebook`}
            target='_blank'>
            <Facebook className={Styles.icon} />
          </a>
          <a
            className={Styles.linkIcon}
            data-test-id='footer-tw'
            href={`${process.env.NEXT_PUBLIC_TWITTER_URL}`}
            rel='noopener noreferrer nofollow me'
            title={`Hire Digital Twitter`}
            target='_blank'>
            <Twitter className={Styles.icon} />
          </a>
          <a
            className={Styles.linkIcon}
            data-test-id='footer-li'
            href={`${process.env.NEXT_PUBLIC_LINKEDIN_URL}`}
            rel='noopener noreferrer nofollow me'
            title={`Hire Digital LinkedIn`}
            target='_blank'>
            <Linkedin className={Styles.icon} />
          </a>
        </div>
      </div>
    </footer>
  );
});

Footer.propTypes = {
  darkFooter: PropTypes.bool,
  data: PropTypes.object,
  locale: PropTypes.string,
};

export default Footer;
